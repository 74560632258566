/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #e5e5e5;
}

/* Contact Page Container */
.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #77bbf0;
}

/* Header Section */
.header {
  width: 100%;
  background-color: #005b9f; /* Blue top bar */
  padding: 20px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #88c0ff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: white;
  margin-top: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* V-Card Section */
.v-card {
  width: 100%;
  height: auto; /* Set a specific height */
  max-width: 450px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: -50px;
  text-align: center;
}

.name {
  font-family: 'Merriweather';
  font-size: 1.4rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

.job {
  font-size: 1.1rem;
  color: #777;
  margin-bottom: 20px;
  font-weight: 800;
}

/* Contact Info Section */
.contact-info {
  margin-top: 10px;
}

.info-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.2s;
}

.info-item:hover {
  background-color: #f1f1f1;
}

.info-item .icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #472d30;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
  margin-right: 10px;
}

.info-item span {
  font-size: 1rem;
  color: #333;
  flex-grow: 1;
  margin-left: 10px;
}

.info-label {
  font-size: 0.85rem;
  color: #999;
}

.add-to-contacts {
  display: block;
  width: 100%;
  padding: 15px 0;
  background-color: #005b9f;
  color: white;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 8px;
  border: none;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.add-to-contacts:hover {
  background-color: #004a80;
}
