/* QRCodePopup.css */
.popup-container {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .popup-container.show {
    opacity: 1;
    visibility: visible;
  }
  
  .popup-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    max-width: 750px; 
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
  }
  
  .content-wrapper {
    display: flex;
    justify-content: space-between;
  }

  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
    color: #777;
  }
  

.qr-code-display-area {
  flex: 1; /* Adjust based on desired width */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px; 
}

  .close:hover {
    color: #333;
  }
  