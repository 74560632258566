.analytics-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  .analytics-title {
    font-size: 2.5rem; 
    font-family: 'Merriweather';
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .analytics-section {
    margin-bottom: 90px;
  }
  
  .analytics-subtitle {
    font-family: 'Merriweather';
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #780000;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid #0e0e0f;
    padding-bottom: 10px;
  }
  
  .view-count-box {
    background-color: #031529;
    font-family: 'Merriweather';
    color: #fff;
    font-size: 2rem;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 400px; 
    height: 75px;
    margin: 0 auto; 
  }
  
  .analytics-table {
    width: 80%;
    border-collapse: collapse;
    margin-top: 20px;
    border-radius: 2px;
    margin: 0 auto; /* Center horizontally */

  }
  
  .analytics-table th,
  .analytics-table td {
    border: 1px solid #160101dd;
    padding: 18px;
    text-align: center;
    font-family: 'Merriweather';

  }
  
  .analytics-table th {
    background-color: #031529;
    color: white;
    font-size: 1.3rem;
    letter-spacing: 1px;
  }
  
  .analytics-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .analytics-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  